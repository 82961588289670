@import "nlib/config";

.expandedListItem {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 1.75rem;
  white-space: nowrap;
  .label {
    display: none;
    min-width: fit-content;
    margin-right: 0.3em;
    font-weight: 500;
  }
  > div {
    display: flex;
    position: relative;
    flex: 1;
    align-items: center;
    width: 1px;
    &.amount,
    &.date {
      max-width: 7rem;
    }
    .icon {
      margin-right: -4px;
      margin-left: -12px;
      color: $uiPrimaryColor;
      font-size: 12px;
      &.plus {
        color: $uiPositiveColor;
      }
      &.minus {
        color: $uiNegativeColor;
      }
    }
    > div {
      max-width: 100%;
      padding: 0 $contentDefaultIndent * 2 0 $contentDefaultIndent;
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .icon {
        display: none;
        margin-right: 0;
        margin-left: 0;
        font-size: 14px;
      }
    }
    .tooltip {
      left: -5px;
      padding: 0;
      overflow: visible;
      .tooltipContent {
        font-weight: normal;
        white-space: normal;
        span {
          margin: 0 4px 0 0;
          white-space: nowrap;
        }
        svg {
          margin: 0 4px 0 0;
          color: $uiPrimaryColor;
        }
        b {
          white-space: nowrap;
        }
      }
    }
  }
  .neutralColor {
    color: $uiNeutralColor;
  }
  .fontWeight {
    font-weight: 500;
  }
  &:hover:not(.header) {
    background-color: $uiBackgroundColor;
  }
  &.header {
    > div {
      color: $uiMediumDarkColor;
      font-size: 0.75rem;
    }
  }
}

[data-mobile] .expandedListItem {
  @media (max-width: $viewportSizeExtraLarge) { // 1200px
    padding: $contentDefaultIndent / 2 0;
    .prevValue {
      display: none;
    }
  }

  @media (max-width: $viewportSizeLarge) { // 992px
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: $contentDefaultIndent;
    &:not(.header) + .expandedListItem {
      border-top: 1px solid $uiBorderColor;
    }
    .label {
      display: inline-block;
    }
    &.header {
      display: none;
    }
    .amount,
    .date,
    .payee,
    .category,
    .description,
    [name] {
      flex: auto !important;
      width: 50% !important;
      max-width: 50% !important;
      height: 100%;
      white-space: normal;
      .icon {
        display: none;
      }
      > div {
        padding: 0 $contentDefaultIndent 0 0;
        .icon {
          display: inline-block;
          margin-right: 0.3em;
        }
        > div {
          display: inline-block;
        }
      }
    }
    [data-empty] {
      display: none;
    }
  }

  @media (max-width: $viewportSizeMedium) { // 768px
  }

  @media (max-width: $viewportSizeSmall) { // 576px
    .amount,
    .date,
    .payee,
    .category,
    .description,
    [name] {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  @media (max-width: $viewportSizeExtraSmall) { // 420px
  }
}
