@import "config";

.activityPage {
  .preloader {
    background-color: rgba($uiWhiteColor, 0.5);
  }
  .container {
    .section {
      margin-bottom: $contentDefaultIndent * 2;
      .title {
        margin-bottom: $contentDefaultIndent;
        color: $uiNeutralColor;
        font-size: 0.75rem;
      }
    }
  }
  .showMore {
    margin: 0 auto;
    padding-bottom: $contentDefaultIndent * 2;
    button {
      padding: 0 $contentDefaultIndent * 4;
    }
  }
}
