@import "nlib/config";

.listItem {
  position: relative;
  padding-bottom: $contentDefaultIndent * 1.5;
  padding-left: $contentDefaultIndent * 5;
  .type {
    display: flex;
    position: absolute;
    top: 1rem;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid $uiBorderColor;
    border-radius: 50%;
    color: $uiMediumDarkColor;
    font-size: 1rem;
    &.failed {
      border-color: $uiNegativeColor;
      color: $uiNegativeColor;
    }
  }
  .card {
    padding: $contentDefaultIndent * 1.5;
    border-radius: $contentDefaultIndent;
    background-color: $uiWhiteColor;
    .title {
      display: flex;
      align-items: center;
      .titleContent {
        display: flex;
        flex: auto;
        align-items: center;
        padding-left: $contentDefaultIndent * 1.5;
      }
      .avatar {
        min-width: 2rem;
      }
      .label {
        flex: auto;
      }
      .date {
        padding-left: $contentDefaultIndent * 1.5;
        color: $uiNeutralColor;
        white-space: nowrap;
      }
    }
    .list {
      margin-top: $contentDefaultIndent;
      padding-left: $contentDefaultIndent * 4.5;
      .footer {
        display: flex;
        justify-content: space-between;
        margin-top: $contentDefaultIndent * 2;
        padding-left: $contentDefaultIndent;
        .more {
          min-height: 20px;
          color: $uiPrimaryColor;
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            text-decoration: none;
          }
        }
        .preloader {
          min-height: 20px;
          font-size: 0.5rem;
        }
      }
    }
  }
  &:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      top: 40px;
      bottom: 0;
      left: $contentDefaultIndent * 1.5;
      border-left: 1px solid $uiBorderColor;
    }
  }
  + .listItem {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: $contentDefaultIndent * 1.5;
      height: 16px;
      border-left: 1px solid $uiBorderColor;
    }
  }
  &.system {
    .avatar {
      border: 1px solid $uiBorderColor;
      img {
        padding: $contentDefaultIndent / 2;
      }
    }
  }
  &:last-child {
    padding-bottom: 0;
  }
}

[data-mobile] .listItem {
  @media (max-width: $viewportSizeExtraLarge) { // 1200px
  }

  @media (max-width: $viewportSizeLarge) { // 992px
    .card {
      .list {
        padding-left: 2.75rem;
      }
    }
  }

  @media (max-width: $viewportSizeMedium) { // 768px
    padding-left: 0;
    &::after,
    &::before {
      display: none;
    }
    .type {
      display: none;
    }
    .title {
      .titleContent {
        padding-left: $contentDefaultIndent;
        line-height: 1.3;
      }
    }
    .card {
      padding: $contentDefaultIndent;
      .list {
        padding-left: 2.5rem;
      }
    }
  }

  @media (max-width: $viewportSizeSmall) { // 576px
    .card {
      .title {
        .titleContent {
          flex-wrap: wrap;
        }
        .label {
          max-width: calc(100% - 2rem);
        }
        .date {
          padding-left: 0;
        }
      }
    }
  }

  @media (max-width: $viewportSizeExtraSmall) { // 420px
    .card {
      .list {
        padding-left: 0;
      }
    }
  }
}
